$(window).scroll(function() {
    if ($(document).scrollTop() > 27) {
        $("#sub-nav").hide();
        $("nav").addClass("shrink")
    } else {
        $("#sub-nav").show();
        $("nav").removeClass("shrink");
    }
});

$(document).ready(function() {

    function ConvertToNum(str) {
        return str.substring(0, str.length - 2);
    }

    function FindMaxHeight(nodes) {
        var maxHeight = 0;

        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            var curHeight = ConvertToNum(window.getComputedStyle(node)["height"]);

            if (curHeight > maxHeight) {
                maxHeight = curHeight;
            }
        }

        return maxHeight;
    }

    function SetHeight(nodes) {
        var height = FindMaxHeight(nodes);

        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            node.style["height"] = height + "px";
        }
    }
//accordion
   /*
    $(".accordion-item__title").on("click", function() {

        if ($(this).hasClass("accordion-active")) {
            $(this).find('.arrow-down').toggleClass('arrow-rotate');
            $(this).removeClass("accordion-active");
            $(this)
                .siblings(".accordion-content")
                .slideUp(200);
        } else {
            $(this).find('.arrow-down').toggleClass('arrow-rotate');
            $(".accordion-item__title").removeClass("accordion-active");
            $(this).addClass("accordion-active");
            $(".accordion-content").slideUp(200);
            $(this)
                .siblings(".accordion-content")
                .slideDown(200);
        }
    });

    */


    $(".accordion-btn").on("click", function() {

        if ($(this).hasClass("accordion-active")) {
            $(this).find('.arrow-down').toggleClass('arrow-rotate');
            $(this).removeClass("accordion-active");
        } else {
            $(this).find('.arrow-down').toggleClass('arrow-rotate');
            $(this).addClass("accordion-active");
        }
    });
    //SetHeight(document.getElementsByClassName("teaser-preview"));

    $(".slider").slick({
        slidesToShow: 1,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 6000,
        prevArrow: document.getElementById("sm-prev"),
        nextArrow: document.getElementById("sm-next")
    });

    $(".slider-lazy").slick({
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 6000,
        prevArrow: document.getElementById("sm-prev"),
        nextArrow: document.getElementById("sm-next")
    });

    $(".slider-medium").slick({
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        dots: true,
        arrows: true,
        autoplay: true,
        adaptiveHeight: true,
        autoplaySpeed: 6000,
        prevArrow: document.getElementById("med-prev"),
        nextArrow: document.getElementById("med-next")
    });

    $("#news-slider").slick({
        slidesToShow: 2,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $(".dropdown-menu li a").click(function() {
        $(this).parents(".dropdown").find(".selection").text($(this).text());
        $(this).parents(".dropdown").find(".selection").val($(this).text());
    });

    $(".lg-slider").slick({
        slidesToShow: 1,
        dots: true,
        fade: true,
        arrows: false
    });

    $(".sm-slider").slick({
        slidesToShow: 2,
        dots: false,
        arrows: true,
        vertical: true,
        asNavFor: '.lg-slider',
        verticalSwiping: true,
        prevArrow: document.getElementById("sm-prev"),
        nextArrow: document.getElementById("sm-next"),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    vertical: false,
                    verticalSwiping: false
                }
            }
        ]
    });

    $('.additional-info button').click(function(){
        if ($(this).hasClass('open')) {
            $('.additional-info button').removeClass('open').prev('div').slideUp();
        } else {
            $('.additional-info button').removeClass('open').prev('div').slideUp();
            $(this).addClass('open').prev('div').slideDown();
        }
    });

    $('.hover.spelle').hover(function(){
        $('.spelle').toggleClass('active');
    });

    $('.hover.gelsenkirchen').hover(function(){
        $('.gelsenkirchen').toggleClass('active');
    });

    $('.hover.magdeburg').hover(function(){
        $('.magdeburg').toggleClass('active');
    });

    $('.hover.ochenbruck').hover(function(){
        $('.ochenbruck').toggleClass('active');
    });

    $('.hover.olszowa').hover(function(){
        $('.olszowa').toggleClass('active');
    });
    $('.hover.losser').hover(function(){
        $('.losser').toggleClass('active');
    });



    $('path.click.spelle').click(function(){
        $('.gelsenkirchen, .magdeburg, .ochenbruck, .olszowa,.losser').removeClass('active');
        $('.spelle').toggleClass('active');
    });

    $('path.click.gelsenkirchen').click(function(){
        $('.spelle, .magdeburg, .ochenbruck, .olszowa,.losser').removeClass('active');
        $('.gelsenkirchen').toggleClass('active');
    });

    $('path.click.magdeburg').click(function(){
        $('.gelsenkirchen, .spelle, .ochenbruck, .olszowa, .losser').removeClass('active');
        $('.magdeburg').toggleClass('active');
    });

    $('path.click.ochenbruck').click(function(){
        $('.gelsenkirchen, .magdeburg, .spelle, .olszowa, .losser').removeClass('active');
        $('.ochenbruck').toggleClass('active');
    });

    $('path.click.olszowa').click(function(){
        $('.gelsenkirchen, .magdeburg, .ochenbruck, .spelle,.losser').removeClass('active');
        $('.olszowa').toggleClass('active');
    });
    $('rect.click.losser').click(function(){
        $('.gelsenkirchen, .magdeburg, .ochenbruck, .spelle ,.olszowa').removeClass('active');
        $('.losser').toggleClass('active');
    });

    $('.standort-item__close').click(function () {
        $(this).parent('.standort-item').removeClass('active');
    });

    /*
     * Fancybox
     */
    $(".fancybox").fancybox();

    $(window).on('DOMContentLoaded load resize', function() {

        var heightLeft = parseInt($('.reference-details__left').css('height'), 10);
        var heightRight = parseInt($('.reference-details__right').css('height'), 10);
        if (heightLeft < heightRight) {
            $('.reference-details__left').css('height', heightRight);
        }

    });

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });


    /*
     * Custom mobile nav
     */
    $(".icon-unfold").click(function(){

        $(this).toggleClass("icon-unfold--down");

        $(this).parents().closest('li').find('dropdown-item').addClass("open");

        var dropdown = $(this).next();

        dropdown.slideToggle();

    });

});
