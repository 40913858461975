function scrollConfigRight() {
    var outerWidth = $(".garage-configurator-config-groups-outer").width();
    $(".garage-configurator-config").animate({scrollLeft: ($(".garage-configurator-config").scrollLeft() + outerWidth-(outerWidth/3))}, 300);
}

function scrollConfigLeft() {
    var outerWidth = $(".garage-configurator-config-groups-outer").width();
    $(".garage-configurator-config").animate({scrollLeft: ($(".garage-configurator-config").scrollLeft() - outerWidth+(outerWidth/3))}, 300);
}

function scrollStepsRight() {
    var outerWidth = $(".garage-configurator-steps-outer .flex-container").width();
    $(".garage-configurator-steps-outer .flex-container-outer").animate({scrollLeft: ($(".garage-configurator-steps-outer .flex-container-outer").scrollLeft() + outerWidth-(outerWidth/3))}, 300);
}

function scrollStepsLeft() {
    var outerWidth = $(".garage-configurator-steps-outer .flex-container").width();
    $(".garage-configurator-steps-outer .flex-container-outer").animate({scrollLeft: ($(".garage-configurator-steps-outer .flex-container-outer").scrollLeft() - outerWidth+(outerWidth/3))}, 300);
}


$(function() {
    'use strict';

    window.GarageConfigurator = {
        lastDragScrollablePosition: 0,

        init: function () {
            var me = this;

            $("#startConfig").on('click', function(){
               $(".welcome-mobile").remove();
            });

            $('.garage-configurator-step-outer').click(function() {
                $('.garage-configurator-step-outer.active').removeClass('active');

                me.showLoader();
                me.loadStep($(this).attr('rel'), function(html) {
                    $('.garage-configurator-config-outer>.container').html(html);
                    $('.gc2023 .garage-configurator-config-outer').html(html);
                    $('.gc2023 .garage-configurator-config').scrollLeft(0);
                    me.registerUiEvents();
                    me.hideLoader();
                });

                $(this).addClass('active');

                var scrollWidth = ($(this).attr('rel')*80)-($(this).parents(".flex-container-outer").width()/2)-40;
                $(this).parents(".flex-container-outer").animate({scrollLeft: scrollWidth}, 300);

            });

            if ($('.garage-configurator-steps-outer .flex-container-outer').length > 0 && !Modernizr.touch) {
                $('.garage-configurator-steps-outer .flex-container-outer').dragscrollable({
                    acceptPropagatedEvent: true,
                    allowY: false
                });
            }
            if (Modernizr.touch) {
                $('.garage-configurator-steps-outer .flex-container-outer').css('overflow-x', 'auto');
            }

            this.registerUiEvents();

            var totalImageCount = $('img.level').length;
            var loadedImageCount = 0;
            $('img.level').on('load', function() {
                loadedImageCount++;
                if(totalImageCount == loadedImageCount) {
                    $('img.level').show();
                    $('svg.loadding').hide();
                }
            });
        },

        changeStep: function(that, me) {
            $('.garage-configurator-step-outer.active').removeClass('active');

            me.showLoader();
            var rel = $(that).attr('rel');
            me.loadStep(rel, function(html) {
                $('.garage-configurator-config-outer>.container').html(html);
                $('.gc2023 .garage-configurator-config-outer').html(html);
                $('.gc2023 .garage-configurator-config').scrollLeft(0);
                me.registerUiEvents();
                me.hideLoader();
            });
            console.log($(that));
            console.log($(that).parent(".flex-container-outer"));

            $('.garage-configurator-step-outer[rel='+rel+']').addClass('active');

            var scrollWidth = ($(that).attr('rel')*80)-($(".garage-configurator-steps-outer .flex-container-outer").width()/2)-40;
            $(".garage-configurator-steps-outer .flex-container-outer").animate({scrollLeft: scrollWidth}, 300);
        },

        showLoader: function() {

        },

        hideLoader: function() {

        },

        loadStep: function(step, callback) {
            $.ajax({
                url: window.location.href,
                method: 'POST',
                data: {
                    action: 'load',
                    step: step
                },
                success: callback
            });
        },

        saveValues: function(step, values, callback) {
            $.ajax({
                url: window.location.href,
                method: 'POST',
                data: {
                    action: 'save',
                    step: step,
                    values: values
                },
                success: callback
            });
        },

        loadImage: function(callback) {
            $.ajax({
                url: window.location.href,
                method: 'POST',
                data: {
                    action: 'loadImage'
                },
                success: callback
            });
        },

        sendRequest: function(data, callback) {
            $.ajax({
                url: window.location.href,
                method: 'POST',
                dataType: 'json',
                encode: true,
                data: {
                    action: 'sendRequest',
                    data: data
                },
                success: callback
            });
        },

        resetValues: function(callback) {
            $.ajax({
                url: window.location.href,
                method: 'POST',
                data: {
                    action: 'resetValues',
                },
                success: callback
            });
            location.reload();
        },

        scrollArrows: function() {
            // ---- Anzeigen oder verstecken der Pfeile
            var outerWidth = $(".garage-configurator-config-groups-outer").width();
            var innerWidth = 0;
            $(".garage-configurator-config-groups-outer>div").each(function(){
                innerWidth += $(this).width();
            });
            if(innerWidth > outerWidth) {
                setTimeout(function(){

                    //Wird ausgeführt wenn das Inhalt neu geladen wird, also beim anklicken einer Option
                    if($(".garage-configurator-config").scrollLeft() > 0) {
                        $(".garage-configurator-config-outer .garage-configurator-arrow.arrow-left").show();
                    } else {
                        $(".garage-configurator-config-outer .garage-configurator-arrow.arrow-left").hide();
                    }

                    if($(".garage-configurator-config").scrollLeft() > (innerWidth - outerWidth - 20)) {
                        $(".garage-configurator-config-outer .garage-configurator-arrow.arrow-right").hide();
                    } else {
                        $(".garage-configurator-config-outer .garage-configurator-arrow.arrow-right").show();
                    }

                    // Wird nur ausgeführt wenn gescrollt wird.
                    $(".garage-configurator-config").scroll(function () {
                        var outerWidth = $(".garage-configurator-config-groups-outer").width();
                        var innerWidth = 0;
                        $(".garage-configurator-config-groups-outer>div").each(function(){
                            innerWidth += $(this).width();
                        });
                        if($(".garage-configurator-config").scrollLeft() > 0) {
                            $(".garage-configurator-config-outer .garage-configurator-arrow.arrow-left").show();
                        } else {
                            $(".garage-configurator-config-outer .garage-configurator-arrow.arrow-left").hide();
                        }

                        if($(".garage-configurator-config").scrollLeft() > (innerWidth - outerWidth - 20)) {
                            $(".garage-configurator-config-outer .garage-configurator-arrow.arrow-right").hide();
                        } else {
                            $(".garage-configurator-config-outer .garage-configurator-arrow.arrow-right").show();
                        }
                    });
                }, 100);
            }  else {
                $(".garage-configurator-config-outer .garage-configurator-arrow.arrow-right").hide();
            }
        },

        scrollStepTabsArrows: function() {
            var outerWidth = $(".garage-configurator-steps-outer .flex-container").width();
            var innerWidth = 0;
            $(".garage-configurator-steps-outer .flex-container>a").each(function(){
                innerWidth += $(this).width();
            });
            if(innerWidth > outerWidth) {
                setTimeout(function(){

                    //Wird ausgeführt wenn das Inhalt neu geladen wird, also beim anklicken einer Option
                    if($(".garage-configurator-steps-outer .flex-container-outer").scrollLeft() > 0) {
                        $(".garage-configurator-steps-outer .garage-configurator-arrow.arrow-left").show();
                    } else {
                        $(".garage-configurator-steps-outer .garage-configurator-arrow.arrow-left").hide();
                    }

                    if($(".garage-configurator-steps-outer .flex-container-outer").scrollLeft() > (innerWidth - outerWidth - 10)) {
                        $(".garage-configurator-steps-outer .garage-configurator-arrow.arrow-right").hide();
                    } else {
                        $(".garage-configurator-steps-outer .garage-configurator-arrow.arrow-right").show();
                    }

                    // Wird nur ausgeführt wenn gescrollt wird.
                    $(".garage-configurator-steps-outer .flex-container-outer").scroll(function () {
                        var outerWidth = $(".garage-configurator-steps-outer .flex-container").width();
                        var innerWidth = 0;
                        $(".garage-configurator-steps-outer .flex-container>a").each(function(){
                            innerWidth += $(this).width();
                        });
                        if($(".garage-configurator-steps-outer .flex-container-outer").scrollLeft() > 0) {
                            $(".garage-configurator-steps-outer .garage-configurator-arrow.arrow-left").show();
                        } else {
                            $(".garage-configurator-steps-outer .garage-configurator-arrow.arrow-left").hide();
                        }
                        if($(".garage-configurator-steps-outer .flex-container-outer").scrollLeft() > (innerWidth - outerWidth - 10)) {
                            $(".garage-configurator-steps-outer .garage-configurator-arrow.arrow-right").hide();
                        } else {
                            $(".garage-configurator-steps-outer .garage-configurator-arrow.arrow-right").show();
                        }
                    });
                }, 100);
            }  else {
                $(".garage-configurator-steps-outer .garage-configurator-arrow.arrow-right").hide();
            }
        },

        configToTextArea: function() {
            var config = "";
            var configMessage = window.configMessage + "\n\n";
            $("#toggle-container ul li").each(function(key, item) {
                config += "- "+$(item).text() +'\n';
            });
            if(config == "") {
                configMessage = window.noConfigMessage
            }
            setTimeout(function(){
                $("#configText").val(configMessage + config)
            }, 100)
        },

        imageDataToForm: function() {
            var imageData = $("#imageDataSource").text();
            setTimeout(function(){
                $("#imageValues").val(imageData)
            }, 100)
        },

        registerUiEvents: function() {
            var me = this;
            me.scrollArrows();
            me.scrollStepTabsArrows();
            me.configToTextArea();
            me.imageDataToForm();

            $('.garage-configurator--nextstep').click(function() {
                me.changeStep($(this), me);
            });

            $('.garage-configurator-config-option>a:not(.fancybox)').click(function () {
                var $configOuter = $(this).parents('.garage-configurator-config');
                var step = $configOuter.attr('rel');

                var $groupOuter = $(this).parents('.garage-configurator-config-group');
                var activeClicked = $(this).hasClass('active');

                if ($groupOuter.find('.garage-configurator-config-option>a.active')) {
                    if ($groupOuter.find('.garage-configurator-config-option>a.active').hasClass('js-path-invert-colors')) {
                        me.invertPathChilds($groupOuter.find('.garage-configurator-config-option>a.active'));
                    }
                    $groupOuter.find('.garage-configurator-config-option>a.active').removeClass('active');
                }

                if ($(this).hasClass('js-path-invert-colors') && !$(this).hasClass('inverted-colors')) {
                    me.invertPathChilds($(this));
                }

                if(!(activeClicked && $groupOuter.hasClass('optional'))) {
                    $(this).addClass('active');
                }

                me.lastDragScrollablePosition = $('.garage-configurator-config').scrollLeft();

                me.saveValues(step, me.collectValues(step), function(html) {
                    $('.garage-configurator-current-configuration-outer').html(html);

                    me.loadImage(function(html) {
                        $('.gc-image-outer').html(html);

                        var totalImageCount = $('img.level').length;
                        var loadedImageCount = 0;
                        $('img.level').on('load', function() {
                            loadedImageCount++;
                            if(totalImageCount == loadedImageCount) {
                                $('img.level').show();
                                $('svg.loadding').hide();
                            }
                        });
                    });

                    $('.garage-configurator-step-outer.active').click();
                });
            });

            $('.garage-configurator-request').submit(function(event) {
                me.sendRequest($(this).serialize(), function(result) {
                    if(result.success) {
                        window.location.reload();
                    } else {
                        $('.garage-configurator-request .form-group').removeClass('has-error');

                        var errorFields = result.errorFields;
                        for (var i = 0; i < errorFields.length; i++) {
                            $('.garage-configurator-request [name="' + errorFields[i] + '"]').parents('.form-group').addClass('has-error');
                        }
                    }
                });
                event.preventDefault();
            });

            $('.vehicle-count input[type="radio"]').click(function() {
                var maxWidth        = parseFloat($(this).data('width'));
                $('.vehicle-count input[type="radio"]').each(function() {
                    if($(this).is(':checked')) {
                        if(parseFloat($(this).data('width')) >= maxWidth) {
                            maxWidth = parseFloat($(this).data('width'));
                        }
                    }
                });

                var slider = document.getElementById('lengthSlider');
                slider.noUiSlider.setHandle(0, maxWidth, true);
            });

            $('.js-path-invert-colors').mouseenter(function () {
                if (!$(this).hasClass('active')) {
                    me.invertPathChilds($(this));
                }
            });

            $('.js-path-invert-colors').mouseleave(function () {
                if (!$(this).hasClass('active')) {
                    me.invertPathChilds($(this));
                }
            });

            if ($('.garage-configurator-config-option>a.active.js-path-invert-colors')) {
                me.invertPathChilds($('.garage-configurator-config-option>a.active.js-path-invert-colors'));
            }

            var slider = document.getElementById('lengthSlider');
            if (slider) {
                var sliderValues = $('#lengthSlider').data('values').split(',');
                var stepIndex = $('#lengthSlider').data('step');
                var sliderValuesCount = sliderValues.length;
                var sliderValuesStep = 100 / (sliderValuesCount - 1);

                var range = {};
                for(var i = 0; i < sliderValuesCount; i++) {
                    if(i === 0) {
                        range.min = parseFloat(sliderValues[i]);
                    } else if(i === (sliderValuesCount - 1)) {
                        range.max = parseFloat(sliderValues[i]);
                    } else {
                        range[(sliderValuesStep * i) + '%'] = parseFloat(sliderValues[i]);
                    }
                }

                noUiSlider.create(slider, {
                    tooltips: false,
                    start: 6,
                    connect: [true, false],
                    range: range,
                    snap: true,
                    pips: {
                        mode: 'steps',
                        density: 1000,
                        format: wNumb({
                            decimals: 2,
                            postfix: 'm'
                        })
                    }
                });
                slider.noUiSlider.on('slide', function () {
                    $('.vehicle-count-outer input[type="radio"]').prop('checked', false);
                });

                slider.noUiSlider.on('set', function () {
                    me.saveValues(stepIndex, me.collectValues(stepIndex), function(html) {
                        $('.garage-configurator-current-configuration-outer').html(html);
                    });
                });

                var sliderValue = parseFloat($('#lengthSlider').data('value'));
                if(sliderValue > 0) {
                    slider.noUiSlider.setHandle(0, sliderValue, false);
                }
            }

            if ($('.garage-configurator-config').length > 0 && $('.gc2023').length == 0) {

                    $('.garage-configurator-config').dragscrollable({
                        dragSelector: '.garage-configurator-config-groups-outer',
                        acceptPropagatedEvent: true
                    });

                if(me.lastDragScrollablePosition > 0) {
                    $('.garage-configurator-config').scrollLeft(me.lastDragScrollablePosition);
                    me.lastDragScrollablePosition = 0;
                }
            }

            if ($('.garage-configurator-config').length > 0 && $('.gc2023').length > 0) {

                if (!Modernizr.touch) {
                    $('.garage-configurator-config').dragscrollable({
                        dragSelector: '.garage-configurator-config-groups-outer',
                        acceptPropagatedEvent: true
                    });
                }

                if(me.lastDragScrollablePosition > 0) {
                    $('.garage-configurator-config').scrollLeft(me.lastDragScrollablePosition);
                    me.lastDragScrollablePosition = 0;
                }

                if (Modernizr.touch) {
                    $('.garage-configurator-config:not(.no-arrows)').css('overflow-x', 'auto');
                }
            }

            $('button#resetValues').unbind();
            $('button#resetValues').on('click', function(){
                me.resetValues();
                console.log("resetValues");
            });

            $('#jumpTosend').unbind();
            $('#jumpTosend').on('click', function() {
                $('.garage-configurator-step-outer[rel="12"]').click();
                console.log("jumpTosend");
            });

        },

        collectValues: function(step) {
            var stepIndex   = step;
            var stepName    = $('.garage-configurator-step-outer[rel="' + stepIndex + '"]').find('span').html();

            var values = {
                'stepIndex': stepIndex,
                'stepName': stepName,
                'values': []
            };

            $('.garage-configurator-config[rel="' + stepIndex + '"]').each(function() {
                if($(this).find('#lengthSlider').length > 0) {
                    var slider = document.getElementById('lengthSlider');
                    values['values'].push({
                        'groupIndex': 1,
                        'groupName': '',
                        'valueIndex': 1,
                        'value': slider.noUiSlider.get(),
                        'valueName': 'ca. ' + slider.noUiSlider.get() + ' mtr.'
                    });
                } else {
                    var $groupOuter = $(this);
                    if($(this).find('.garage-configurator-config-group.multiple').length > 0) {
                        $groupOuter = $(this).find('.garage-configurator-config-group.multiple');
                    }

                    var groupIndex = 0;
                    $groupOuter.each(function() {
                        groupIndex++;

                        var groupName = $(this).children('strong').data('groupName');

                        $(this).find('.garage-configurator-config-option').each(function() {
                            if($(this).children('a.active').length > 0) {
                                var valueName = $(this).children('div').html();
                                values['values'].push({
                                    'groupIndex': groupIndex,
                                    'groupName': groupName,
                                    'valueIndex': $(this).children('a').attr('rel'),
                                    'valueName': valueName
                                });
                            }
                        });
                    });
                }
            });

            return values;
        },

        invertPathChilds: function ($element) {
            if (!$element.hasClass('inverted-colors')) {
                $element.addClass('inverted-colors')
            } else {
                $element.removeClass('inverted-colors')
            }

            var colorBlue = 'rgb(0, 157, 224)';
            var colorOrange = 'rgb(255, 103, 0)';
            var colorGray = 'rgb(94, 81, 72)';
            var colorGray2 = 'rgb(94, 82, 73)';
            var colorGray3 = 'rgb(95, 82, 74)';
            var colorGray4 = 'rgb(85, 85, 85)';
            var colorBlack = 'rgb(0, 0, 0)';
            var colorWhite = 'rgb(255, 255, 255)';
            var colorWhite2 = 'rgb(255, 255, 254)';
            var colorWhite3 = 'rgb(255, 254, 255)';
            var colorWhite4 = 'rgb(254, 255, 255)';

            if ($('.gc2023').length === 0) {
                $element.find('svg *').each(function () {
                    if ($(this).css('fill') == colorBlue) {
                        $(this).css('fill', colorWhite);
                    } else if ($(this).css('fill') == colorWhite) {
                        $(this).css('fill', colorBlue);
                    } else if ($(this).css('fill') == colorGray || $(this).css('fill') == colorGray2 || $(this).css('fill') == colorGray3 || $(this).css('fill') == colorGray4) {
                        $(this).css('fill', colorWhite2);
                    } else if ($(this).css('fill') == colorWhite2) {
                        $(this).css('fill', colorGray);
                    } else if ($(this).css('fill') == colorWhite3) {
                        $(this).css('fill', colorBlack);
                    } else if ($(this).css('fill') == colorBlack) {
                        $(this).css('fill', colorWhite3);
                    } else if ($(this).css('fill') == colorOrange) {
                        $(this).css('fill', colorWhite4);
                    } else if ($(this).css('fill') == colorWhite4) {
                        $(this).css('fill', colorOrange);
                    }

                    if ($(this).css('stroke') == colorBlue) {
                        $(this).css('stroke', colorWhite);
                    } else if ($(this).css('stroke') == colorWhite) {
                        $(this).css('stroke', colorBlue);
                    } else if ($(this).css('stroke') == colorWhite2) {
                        $(this).css('stroke', colorGray);
                    } else if ($(this).css('stroke') == colorGray) {
                        $(this).css('stroke', colorWhite2);
                    } else if ($(this).css('stroke') == colorBlack) {
                        $(this).css('stroke', colorWhite3);
                    } else if ($(this).css('stroke') == colorWhite3) {
                        $(this).css('stroke', colorBlack);
                    } else if ($(this).css('stroke') == colorWhite4) {
                        $(this).css('stroke', colorOrange);
                    } else if ($(this).css('stroke') == colorOrange) {
                        $(this).css('stroke', colorWhite4);
                    }
                });
            }
        }
    };

    window.GarageConfigurator.init();
});